import LoginBody from "./components/LoginBody";

const Login = () => {
  return (
    <>
      <LoginBody/>
    </>
  );
}
 
export default Login;